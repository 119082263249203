import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import Seo from "../components/seo";
import { CaseStudyCard } from "../components/Cards";
import { Header, SubHeader, Body } from "../components/Typography";
import { SiteContent } from "../components/Layouts";
import { Colors } from "../theme";
import { CaseStudyData } from "../data/case-studies";
import "../theme/css/animations.css";

const ServicesWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: ${Colors.DarkPurple};
  overflow: hidden;
  min-height: 1200px;
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  min-height: 700px;

  @media only screen and (max-width: 780px) {
    min-height: 500px;
  }
`;



const Services = () => {

    return (
      <ServicesWrapper>
        <Seo title="Thumbs Up Studio Portfolio Work - Web Design & Development" />

        <HeroWrapper>
          <SiteContent center>
            <SubHeader large>Our</SubHeader>
            <Header h1 color={Colors.White}>
              Services.
            </Header>
            <Body maxWidth color={Colors.White}>
              So much work. So little time.
              <br />
              Check back soon for our services.
            </Body>
          </SiteContent>
        </HeroWrapper>

      </ServicesWrapper>
    );
}

export default Services;
